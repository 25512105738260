import * as React from "react"
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components'
import { withPrefix } from "gatsby-link";

// styles
const pageStyles = {
  color: "#56C5E3",
  backgroundColor: "#9342f5",
  padding: 60,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

const mainDauerImageStyle = {
  // marginRight: "auto",
  // marginLeft: "auto",
  // maxHeight: 500,
  // minWidth: 800,
}

const mainDauerImageFrameStyles = {
  marginRight: "auto",
  marginLeft: "auto",
  // marginBottom: "auto",
  // marginTop: "auto",
  margin: "auto",
  minWidth: 50,
  // display: "block",
}

const TextStyles = styled.p`
  color: "#56C5E3";
  margin-top: 0;
  margin-bottom: 64;
  font-size: xx-large;
  text-align: center;
  font-weight: bold;
`

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
        <title>Dauer Ellis Rocks</title>
        <div align="center">
          <TextStyles>
            Deal with it.
          </TextStyles>
          <StaticImage style={mainDauerImageFrameStyles} 
            // src="../images/highrezdauer.png"
            src="../images/dauer1.jpeg"
            alt="Dauer Ellis totally rocks!"
          />
        </div>

    </main>
  )
}

export default IndexPage
